import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking, ActivityIndicator} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, Timestamp, orderBy} from "firebase/firestore";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import  * as DocumentPicker from 'expo-document-picker';
import { getAuth } from "firebase/auth";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';


export default function FilaProfessorDAO({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);
    const [email, setEmail] = useState("");
    const [diplo, setDiplo] = useState("");
    const [cas, setCas] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [reload, setReload] = useState("");
    const [email2, setEmail2] = useState("");
    const [dataAtual, setDataAtual] = useState("")
    const [link, setLink] = useState("")

    const auth = getAuth();
    const user = auth.currentUser;
    const [emailProf, setEmailProf]= useState("");
    const [cont, setCont] = useState(0)
    const [nome, setNome] = useState("")

    const [referencia, setReferencia] = useState(null);
    const [image, setImage] = useState("");
    const [uploading2, setUploading2] = useState(false);

    const [referencia2, setReferencia2] = useState(null);
    const [image2, setImage2] = useState("");
    const [uploading3, setUploading3] = useState(false);

    const [referencia3, setReferencia3] = useState(null);
    const [image3, setImage3] = useState("");
    const [uploading4, setUploading4] = useState(false);
   
    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }

    useEffect(() => {
        getResumos();
        setReload("");
        setOpen(false);
        setOpen2(false);
        setEmail(false);
        setEmail2("");
        setRetorno([])
    }, [reload])

    useFocusEffect(
        React.useCallback(() => {
            if (user !== null) {
                user.providerData.forEach((profile) => {
                    setEmailProf(profile.email)
                });
            } 

            for (var i = 0; i <= 2; i++) {
                setCont(cont + i)
            }
        }, [])
      );

      const getNome = async () => {
        for (var i = 0; i <= 2; i++) {
            const db = getFirestore();
            
            const citiesRef = collection(db, "Usuarios");
            const q = query(citiesRef, where("email", "==", emailProf));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
                setNome(doc.data().nome);
            });
        }
    }

    useEffect(() =>{
        getNome();
    },[cont])
    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("validata", "!=", ""), where("Prof", "==", ""),  where("status", "==", "Esperando análisis de los profesores para dao"), orderBy("validata"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
                nomePaciente: doc.data().nomePaciente,
            };
            array.push(Obj)
        });

 
        ordenarArrayPorEnvioDiploNumeroCasoEValidata(array)
    }

    function ordenarArrayPorEnvioDiploNumeroCasoEValidata(array) {
        // Mover os elementos com diplo igual a "DAO" para o topo
        const daoElements = array.filter(item => item.diplo === 'DAO');
        const casoExtraElements = array.filter(item => item.numeroCaso === 'Caso Extra' && item.diplo !== 'DAO');
        const otherElements = array.filter(item => item.diplo !== 'DAO' && item.numeroCaso !== 'Caso Extra');
    
        // Ordenar o array otherElements por validata
        otherElements.sort((a, b) => {
            // Função para interpretar a string de validata como uma data
            const parseValidataDate = (validata) => {
                const [day, month, year] = validata.split('/').map(Number);
                return new Date(year, month - 1, day); // Mês em JavaScript é 0-indexed
            };
    
            // Comparar as datas de validata
            const dateA = parseValidataDate(a.validata);
            const dateB = parseValidataDate(b.validata);
    
            // Comparar considerando primeiro o ano, depois o mês e por último o dia
            if (dateA.getFullYear() !== dateB.getFullYear()) {
                return dateA.getFullYear() - dateB.getFullYear();
            } else if (dateA.getMonth() !== dateB.getMonth()) {
                return dateA.getMonth() - dateB.getMonth();
            } else {
                return dateA.getDate() - dateB.getDate();
            }
        });
    
        // Combinar os arrays ordenados
        const arrayOrdenado = [...daoElements, ...casoExtraElements, ...otherElements];
    
        setRetorno(arrayOrdenado);
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("numeroCaso", "==", cas), where("ifoUsuario.diplo", "==", diplo), where("status", "==", "Esperando análisis de los profesores para dao"), orderBy("email"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data.seconds,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
                nomePaciente: doc.data().nomePaciente,
                CasoDAO: "Não Finalizado"
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("Prof", "==", nome), where( "status", "==", "Planejamento en curso"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                Prof: doc.data().Prof,
                tipo: "CASO",
            };
            array.push(Obj)
        });

        setRetorno3(array)
    }

    const getLink = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Links DAO");
        const q = query(citiesRef, where("atual", "==", "Sim"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            setLink(doc.data().link)
        });
    }

    const pickImage = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading2(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url );
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    const pickImage2 = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage2(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading3(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url );
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia2(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    const pickImage3 = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage3(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading4(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url );
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia3(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    console.log(referencia)


    useEffect(() => {
        getResumos();
        getLink();
        Tick();
    }, [])

    useEffect(() => {
        getResumos2();
        getResumos3();
    },[email])

    useEffect(() => {
        setUploading2(false);
    },[referencia])

    useEffect(() => {
        setUploading3(false);
    },[referencia2])

    useEffect(() => {
        setUploading4(false);
    },[referencia3])

    console.log(retorno3.length)

    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    {open !== true ?
                        <View>
                            <Text style={styles.Title}>Caso Clínico</Text>
                        </View>
                        :
                        <View>
                            <Text style={styles.Title}>Caso clínico do aluno</Text>
                        </View>
                    }
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>
                    {open ===  false &&
                        <TouchableOpacity style={{position: "absolute", top: 0, right: 30, marginTop: 20}} onPress={() => {getResumos()}} >
                            <MaterialCommunityIcons
                                name="refresh-circle"
                                size={50}
                                color="#ffffff" />
                        </TouchableOpacity>
                    }

                    <View style={{position: "absolute", top: 30, right: 150}}>
                        <Text style={{fontSize: 30, textAlign: 'center', color: "#FFF", fontWeight: "bold"}}>Nº Casos: {retorno.length}</Text>
                    </View>

                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginTop: 30, marginBottom: 30 }}>

                {open !== true ?
                    <>
                    <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row'}}>
                        <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("FilaProfessor")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Fila de espera</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                        }} onPress={() => navigation.navigate("MeusCasos")}>

                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Meus casos clínicos</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }}
                        onPress={() => navigation.navigate("AguardandoAluno")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aguardando Arquivos</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                            onPress={() => navigation.navigate("ProfConsulta")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Pesquisa de Casos Clínicos</Text>
                        </TouchableOpacity>

                                            
                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                            onPress={() => navigation.navigate("CasosConcluidosProf")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Meus casos finalizados</Text>
                        </TouchableOpacity>

                    </View>

                    <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row'}}>
                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFF", margin: 20,}} 
                            onPress={() => navigation.navigate("FilaProfessorDAO")}>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Fila)</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                            onPress={() => navigation.navigate("CasosConcluidosDAO")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Finalizados)</Text>
                        </TouchableOpacity>
                    </View>
                    </>
                    :
                    <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                            <TouchableOpacity onPress={() => {setOpen(false), setEmail(""), setCas("")}}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}>Fila de espera</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}> -{'>'} </Text>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold"}}>Documentos Enviados</Text>
                        </View>
                    }
                </View>

                {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                        
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Nº Caso Clínico: {item.numeroCaso}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.Envio}        Paciente: {item.nomePaciente}</Text>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 20 }}>
                                {item.Anamnesis !== "" &&
                                    <TouchableOpacity style={styles.buttonView1} onPress={() => {
                                            Linking.openURL(item.Anamnesis);
                                            }}>
                                            <Text style={styles.textView}>📄 Guía de Anamnesis</Text>
                                    </TouchableOpacity>
                                }

                                <TouchableOpacity style={styles.buttonView2} onPress={() => {
                                        Linking.openURL(item.DocPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Documentação do Paciente</Text>
                                </TouchableOpacity>


                                {item.CefalometriaPaciente !== "" &&
                                    <TouchableOpacity style={styles.buttonView3} onPress={() => {
                                            Linking.openURL(item.CefalometriaPaciente);
                                            }}>
                                            <Text style={styles.textView}>📄 Cefalometría</Text>
                                    </TouchableOpacity>
                                }
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 20 }}>

                                <TouchableOpacity style={styles.buttonProblema} onPress={() => open2 === false ? setOpen2(true): setOpen2(false)}>

                                    {open2 === false &&
                                    <MaterialCommunityIcons
                                        name="close"
                                        size={25}
                                        color="#000000"
                                        style={{ alignSelf: 'center' }}
                                    />
                                    }

                                    <Text style={{ textAlign: 'center', fontSize: 15, color: '#000000', }}>{open2 === false ? "Reprovar" : "Voltar"}</Text>

                                </TouchableOpacity>

                                {open2 === false &&
                                    <TouchableOpacity style={styles.buttonOk} onPress={async () => {
                                       
                                            const db = getFirestore();
                                            const docRef = await addDoc(collection(db, "Resumos"), {
                                                to: [item.email],
                                                message: {
                                                    subject: 'DAO - Caso clínico aprovado, reserva tu sesión individual!',
                                                    html: '<style>*{ font-family: sans-serif; font-Size: 15px } </style><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/DAO%2FEMAIL%20(1).png?alt=media&token=912961b0-fbea-4047-bbd5-99e04bf55c0b" width="100%"/><p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p>La documentación del paciente '+ `${item.nomePaciente}` +' fue aprobada! ✅</p><p></p><p>Ahora ya puedes programar tu sesión de planificación individual con uno de nuestros profesores 🤗.</p><a href="'+ `${link}`+'" target="_blank">AGENDAR SESIÓN DE PLANIFICACIÓN DE CASO CLÍNICO</a><p>¡Nos vemos pronto en nuestra sesión personalizada en vivo!<p><p>Un abrazo,</p><p>Equipo de Soporte Profª Rayane Pinto<br/><p style="font-size: 13px">Equipo de soporte<br/>suporte@rayanepinto.com<br/>Odontología Sin Fronteras<br>Siempre por más sonrisas en la latino-america 🌍<br/>#ortodoncia #correctiva #preventiva #dao</p>',
                                                },
                                                email: item.email,
                                                ifoUsuario: item.consulta,
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente,
                                                data: Timestamp.fromDate(new Date()),
                                                Envio: item.Envio,
                                                numeroCaso: item.numeroCaso,
                                                status: "Finalizado", 
                                                validata: item.validata,
                                                initData: dataAtual,
                                                Prof: nome,
                                                Planej: "",
                                                face: "",
                                                tipo: "CASO",
                                                Motivo: item.Motivo,
                                                Arquivo: item.Arquivo,
                                                CasoDAO: "Finalizado",
                                                nomePaciente: item.nomePaciente,
                                            });

                                            const docRef2 = await addDoc(collection(db, "CasosDAO"), {
                                                email: item.email,
                                                ifoUsuario: item.consulta,
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente,
                                                data: Timestamp.fromDate(new Date()),
                                                Envio: item.Envio,
                                                numeroCaso: item.numeroCaso,
                                                status: "Finalizado", 
                                                validata: item.validata,
                                                initData: dataAtual,
                                                Prof: nome,
                                                Planej: "",
                                                face: "",
                                                tipo: "CASO",
                                                Motivo: item.Motivo,
                                                Arquivo: item.Arquivo,
                                                CasoDAO: "Finalizado",
                                                nomePaciente: item.nomePaciente,
                                            });

                                            const docRef3 = await addDoc(collection(db, "Resumos"), {
                                                to: ["suporte@rayanepinto.com"],
                                                message: {
                                                    subject: 'DAO - Caso clínico aprovado, reserva tu sesión individual!',
                                                    html: '<style>*{ font-family: sans-serif; font-Size: 15px } </style><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/DAO%2FEMAIL%20(1).png?alt=media&token=912961b0-fbea-4047-bbd5-99e04bf55c0b" width="100%"/><p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p>La documentación del paciente '+ `${item.nomePaciente}` +' fue aprobada! ✅</p><p></p><p>Ahora ya puedes programar tu sesión de planificación individual con uno de nuestros profesores 🤗.</p><a href="'+ `${link}`+'" target="_blank">AGENDAR SESIÓN DE PLANIFICACIÓN DE CASO CLÍNICO</a><p>¡Nos vemos pronto en nuestra sesión personalizada en vivo!<p><p>Un abrazo,</p><p>Equipo de Soporte Profª Rayane Pinto<br/><p style="font-size: 13px">Equipo de soporte<br/>suporte@rayanepinto.com<br/>Odontología Sin Fronteras<br>Siempre por más sonrisas en la latino-america 🌍<br/>#ortodoncia #correctiva #preventiva #dao</p>',
                                                },
                                                email: item.email,
                                                ifoUsuario: item.consulta,
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente,
                                                data: Timestamp.fromDate(new Date()),
                                                Envio: item.Envio,
                                                numeroCaso: item.numeroCaso,
                                                status: "Finalizado", 
                                                validata: item.validata,
                                                initData: dataAtual,
                                                Prof: nome,
                                                Planej: "",
                                                face: "",
                                                tipo: "CASO",
                                                Motivo: item.Motivo,
                                                Arquivo: item.Arquivo,
                                                CasoDAO: "Finalizado",
                                                nomePaciente: item.nomePaciente,
                                            });
                                            
                                            await deleteDoc(doc(db, "Resumos", item.id));
                                            alert("Caso Clínico Adicionado a aba 'Casos DAO (Documentos)'", "...")
                                        
                                            setReload("Recarrega")
                                       
                                    }}>

                                        <MaterialCommunityIcons
                                            name="check-bold"
                                            size={25}
                                            color="#000000"
                                            style={{ alignSelf: 'center' }}
                                        />
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#000000', }}>Documentação Ok</Text>
                                    </TouchableOpacity>
                                }
                                </View>

                                <View style={styles.viewReprovar}>
                                    
                                {open2 === true &&

                                    <TextInput style={styles.input}
                                        placeholder="Motivo de reprovação..."
                                        placeholderTextColor="#000000"
                                        multiline = {true}
                                        numberOfLines = {4}
                                        type="text"
                                        onChangeText={(text) => setEmail2(text)}
                                        value={email2} />
                                }


                                <View style={{flexDirection: 'row'}}>
                                {open2 === true && uploading2 === false && referencia === null ?
                                
                                <TouchableOpacity style={styles.botaoAnexo} onPress={pickImage}>
                                    <Text style={styles.textAnexo}>Anexar Imagem</Text>
                                </TouchableOpacity>
                                :
                                <ActivityIndicator style={{ margin: 25 }} animating={uploading2} size={60} color="#FFF" />
                                }

                                {open2 === true && uploading2 === false && referencia !==  null &&
                                <View style={styles.viewUpload}>
                                    <Text style={styles.nomeImg}>📄 {image}</Text>
                            
                                    <TouchableOpacity onPress={() => {setReferencia(null), setImage(null)}} style={styles.botaoEliminar}>
                                        <Text style={styles.textEliminar}>X</Text>
                                    </TouchableOpacity>
            
                                </View>
                                
                                }

                                {open2 === true && uploading3 === false && referencia2 === null ?
                                
                                <TouchableOpacity style={styles.botaoAnexo} onPress={pickImage2}>
                                    <Text style={styles.textAnexo}>Anexar Imagem</Text>
                                </TouchableOpacity>
                                :
                                <ActivityIndicator style={{ margin: 25 }} animating={uploading3} size={60} color="#FFF" />
                                }

                                {open2 === true && uploading3 === false && referencia2 !==  null &&
                                <View style={styles.viewUpload}>
                                    <Text style={styles.nomeImg}>📄 {image2}</Text>
                            
                                    <TouchableOpacity onPress={() => {setReferencia2(null), setImage2(null)}} style={styles.botaoEliminar}>
                                        <Text style={styles.textEliminar}>X</Text>
                                    </TouchableOpacity>
            
                                </View>
                                
                                }

                            {open2 === true && uploading4 === false && referencia3 === null ?
                                
                                <TouchableOpacity style={styles.botaoAnexo} onPress={pickImage3}>
                                    <Text style={styles.textAnexo}>Anexar Imagem</Text>
                                </TouchableOpacity>
                                :
                                <ActivityIndicator style={{ margin: 25 }} animating={uploading4} size={60} color="#FFF" />
                                }

                                {open2 === true && uploading4 === false && referencia3 !==  null &&
                                <View style={styles.viewUpload}>
                                    <Text style={styles.nomeImg}>📄 {image3}</Text>
                            
                                    <TouchableOpacity onPress={() => {setReferencia3(null), setImage3(null)}} style={styles.botaoEliminar}>
                                        <Text style={styles.textEliminar}>X</Text>
                                    </TouchableOpacity>
            
                                </View>
                                
                                }
                                </View>

                                {open2 === true && email2 === "" &&

                                    <TouchableOpacity style={styles.botaoEnv}>

                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                    </TouchableOpacity>

                                }

                                {open2 === true && email2 !== "" &&

                                    <TouchableOpacity style={styles.buttonProblema2} onPress={async () => {

                                        const db = getFirestore();
                                        const docRef = await addDoc(collection(db, "Resumos"), {
                                            to: [item.email],
                                            message: {
                                                subject: 'DAO - Tu caso clínico fue reprobado',
                                                html: '<style>*{ font-family: sans-serif; font-Size: 15px }</style> <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/DAO%2FEMAIL%20(1).png?alt=media&token=912961b0-fbea-4047-bbd5-99e04bf55c0b" width="100%"/><p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p>Desafortunadamente la documentación del paciente ' + `${item.nomePaciente}` +' no ha sido aprobada.</p><p>Motivo: ' + email2 + '</p><p>Haga las correcciones necesarias y vuelva a enviar los archivos aquí: 👇</p><p><a href="https://casosclinicos.odontologiasinfronteras.com/dao-sesiones-individuales/" target="_blank">https://casosclinicos.odontologiasinfronteras.com/dao-sesiones-individuales/</a></p><p>Espero tu caso.</p><p>Un abrazo,</p><p style="font-size: 13px">Equipo de soporte<br/>suporte@rayanepinto.com<br/>Odontología Sin Fronteras<br>Siempre por más sonrisas en la latino-america 🌍<br/>#ortodoncia #correctiva #preventiva #dao</p>',
                                                attachments: [{
                                                    filename: "arquivo1.png",
                                                    path:referencia
                                                    },
                                                    {
                                                        filename: "arquivo2.png",
                                                        path:referencia2
                                                    },
                                                    {
                                                        filename: "arquivo3.png",
                                                        path:referencia3
                                                    }
                                                ]
                                            },
                                            email: item.email,
                                            ifoUsuario: item.consulta,
                                            Anamnesis: item.Anamnesis,
                                            DocPaciente: item.DocPaciente,
                                            CefalometriaPaciente: item.CefalometriaPaciente,
                                            data: Timestamp.fromDate(new Date()),
                                            status: "Reprobado por los profesores",
                                            Envio: item.Envio,
                                            numeroCaso: item.numeroCaso, 
                                            validata: item.validata,
                                            initData: dataAtual,
                                            Prof: nome,
                                            Planej: "",
                                            face: "",
                                            tipo: "CASO",
                                            Motivo: item.Motivo,
                                            Arquivo: item.Arquivo,
                                            CasoDAO: "Não Finalizado",
                                            nomePaciente: item.nomePaciente,
                                        });

                                        const docRef2 = await addDoc(collection(db, "ConsultaErro"), {
                                            email: item.email,
                                            ifoUsuario: item.consulta,
                                            Anamnesis: item.Anamnesis,
                                            DocPaciente: item.DocPaciente,
                                            CefalometriaPaciente: item.CefalometriaPaciente,
                                            data: Timestamp.fromDate(new Date()),
                                            status: "Reprobado por los profesores",
                                            Envio: item.Envio,
                                            numeroCaso: item.numeroCaso, 
                                            validata: item.validata,
                                            initData: dataAtual,
                                            Prof: nome,
                                            Planej: "",
                                            face: "",
                                            tipo: "CASO",
                                            Motivo: item.Motivo,
                                            Arquivo: item.Arquivo,
                                            CasoDAO: "Não Finalizado",
                                            nomePaciente: item.nomePaciente,
                                        });


                                        const docRef3 = await addDoc(collection(db, "Historico"), {
                                            email: item.email,
                                            ifoUsuario: item.consulta,
                                            Anamnesis: item.Anamnesis,
                                            DocPaciente: item.DocPaciente,
                                            CefalometriaPaciente: item.CefalometriaPaciente,
                                            data: Timestamp.fromDate(new Date()),
                                            status: "Reprobado por los profesores",
                                            Envio: item.Envio,
                                            numeroCaso: item.numeroCaso, 
                                            validata: item.validata,
                                            initData: dataAtual,
                                            Prof: nome,
                                            Planej: "",
                                            face: "",
                                            tipo: "CASO",
                                            Motivo: item.Motivo,
                                            Arquivo: item.Arquivo,
                                            CasoDAO: "Não Finalizado",
                                            nomePaciente: item.nomePaciente,
                                        });

                                        const docRef4 = await addDoc(collection(db, "Resumos"), {
                                            to: ["suporte@rayanepinto.com"],
                                            message: {
                                                subject: 'DAO - Tu caso clínico fue reprobado',
                                                html: '<style>*{ font-family: sans-serif; font-Size: 15px }</style> <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/DAO%2FEMAIL%20(1).png?alt=media&token=912961b0-fbea-4047-bbd5-99e04bf55c0b" width="100%"/><p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p>Desafortunadamente la documentación del paciente ' + `${item.nomePaciente}` +' no ha sido aprobada.</p><p>Motivo: ' + email2 + '</p><p>Haga las correcciones necesarias y vuelva a enviar los archivos aquí: 👇</p><p><a href="https://casosclinicos.odontologiasinfronteras.com/dao-sesiones-individuales/" target="_blank">https://casosclinicos.odontologiasinfronteras.com/dao-sesiones-individuales/</a></p><p>Espero tu caso.</p><p>Un abrazo,</p><p style="font-size: 13px">Equipo de soporte<br/>suporte@rayanepinto.com<br/>Odontología Sin Fronteras<br>Siempre por más sonrisas en la latino-america 🌍<br/>#ortodoncia #correctiva #preventiva #dao</p>',
                                                attachments: [{
                                                    filename: "arquivo1.png",
                                                    path:referencia
                                                    },
                                                    {
                                                        filename: "arquivo2.png",
                                                        path:referencia2
                                                    },
                                                    {
                                                        filename: "arquivo3.png",
                                                        path:referencia3
                                                    }
                                                ]
                                            },
                                            email: item.email,
                                            ifoUsuario: item.consulta,
                                            Anamnesis: item.Anamnesis,
                                            DocPaciente: item.DocPaciente,
                                            CefalometriaPaciente: item.CefalometriaPaciente,
                                            data: Timestamp.fromDate(new Date()),
                                            status: "Reprobado por los profesores",
                                            Envio: item.Envio,
                                            numeroCaso: item.numeroCaso, 
                                            validata: item.validata,
                                            initData: dataAtual,
                                            Prof: nome,
                                            Planej: "",
                                            face: "",
                                            tipo: "CASO",
                                            Motivo: item.Motivo,
                                            Arquivo: item.Arquivo,
                                            CasoDAO: "Não Finalizado",
                                            nomePaciente: item.nomePaciente,
                                        });

                                        await deleteDoc(doc(db, "Resumos", item.id));
                                        setReload("Recarrega")
                                        alert("Email de reprovação enviado para o aluno", "...")
                                       
                                    }}>
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                    </TouchableOpacity>
                                }

                            </View>
   
                        </>
                    )
                    })}
                </View>
            }
            <View style={{justifyContent: "center",  alignItems: "center"}}>
            <ScrollView horizontal={true} style={{flex: 1}}>
            {open ===  false &&
                <View style={{margin: 30, justifyContent: "center", alignItems: "center"}}>
                    <View style={{flexDirection: 'row',  }}>
                        <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 5, borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Email</Text>
                        </View>
                       
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Nome do Paciente</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 5, fontWeight: "bold"}}>No. Caso Clínico</Text>
                        </View>
                        
                        <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Abrir Documentos</Text>
                        </View>
                    </View>

                    {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={{flexDirection: 'row', }}>
                                <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.nome}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.email}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.nomePaciente}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.numeroCaso}</Text>
                                </View>

                                <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15, justifyContent: "center", alignItems: "center"}}>

                                    <TouchableOpacity onPress={() => {
                                        setEmail(item.email),
                                        setCas(item.numeroCaso),
                                        setDiplo(item.diplo),
                                        setOpen(true)
                                    }}>
                                        <Text style={{color: "#fff", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Ver Caso Clínico</Text>
                                    </TouchableOpacity>
                                </View>

                            </View>
                        </>
                    )
                    })}
                </View>
            }
            </ScrollView>
            </View>

            </View>
        </>
    )

}