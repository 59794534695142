import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking, ActivityIndicator} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, Timestamp, limit, orderBy} from "firebase/firestore";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import  * as DocumentPicker from 'expo-document-picker';
import { getAuth } from "firebase/auth";
import { useFocusEffect } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable'
import styles from './style';




export default function MeusCasos({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [inicio, setInicio] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] =  useState(false)
    const [open4, setOpen4] =  useState(false)
    const [open5, setOpen5] =  useState(false)
    const [reload, setReload] = useState("");
    const [email2, setEmail2] = useState("");
    const [url, setUrl] = useState("");
    const [motivo, setMotivo] = useState("");
    const [facebook, setFacebook] = useState("")
    const [emailAluno, setEmailAluno]= useState("");

    const [cas, setCas] = useState("");
    const [diplo, setDiplo] = useState("");
   
    const [dataAtual, setDataAtual] = useState("");

    const [referencia, setReferencia] = useState(null);
    const [image, setImage] = useState("");
    const [uploading2, setUploading2] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;
    const [emailProf, setEmailProf]= useState("");
    const [cont, setCont] = useState(0)
    const [nome, setNome] = useState("")
    const [permissaoDAO, setPermissaoDAO] = useState("")

    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }
    
      useEffect(() => {
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmailProf(profile.email)
            });
        } 

        for (var i = 0; i < 2; i++) {
            setCont(cont + 1)
        }
      },[])

      const getNome = async () => {
        
            const db = getFirestore();
            
            const citiesRef = collection(db, "Usuarios");
            const q = query(citiesRef, where("email", "==", emailProf));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            
                setNome(doc.data().nome);
            });
        
    }

    const getPermissao = async () => {
        const db = getFirestore();
            
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", emailProf), where("dao", "==", "Sim"));

        const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
                setPermissaoDAO(doc.data().dao);
        });
      
    }

    useEffect(() =>{
        getNome();
        Tick();
        getPermissao();
    },[cont])

    useEffect(() => {
        getResumos();
        setReload("");
        setRetorno([])
    }, [reload])

    const T = () =>{
    {retorno && retorno.map((item) => {
    
        if(item.diplo === "Intercept" && item.grupo === "Grupo10"){
            setUrl("https://www.facebook.com/groups/diplomadointerceptgrupo10")
        }
        if(item.diplo === "Intercept" && item.grupo === "Grupo11"){
            setUrl("https://www.facebook.com/groups/diplomadointerceptgrupo11")
        }
        if(item.diplo === "Intercept" && item.grupo === "Grupo12"){
            setUrl("https://www.facebook.com/groups/diplomadointerceptgrupo12")
        }
        if(item.diplo === "Intercept" && item.grupo === "Grupo13"){
            setUrl("https://www.facebook.com/groups/diplomadointerceptgrupo13")
        }
        if(item.diplo === "Intercept" && item.grupo === "Grupo14"){
            setUrl("https://www.facebook.com/groups/diplomadointerceptgrupo14")
        }
        if(item.diplo === "Intercept" && item.grupo === "Grupo15"){
            setUrl("https://www.facebook.com/groups/diplomadointerceptgrupo15")
        }

        if(item.diplo === "Formula" && item.grupo === "Grupo4"){
            setUrl("https://www.facebook.com/groups/formulacorrectiva4")
        }
        if(item.diplo === "Formula" && item.grupo === "Grupo5"){
            setUrl("https://www.facebook.com/groups/formulacorrectiva5")
        }
        if(item.diplo === "Formula" && item.grupo === "Grupo6"){
            setUrl("https://www.facebook.com/groups/formulacorrectiva6")
        }
        if(item.diplo === "Formula" && item.grupo === "Grupo7"){
            setUrl("https://www.facebook.com/groups/formulacorrectiva7")
        }
        if(item.diplo === "Formula" && item.grupo === "Grupo8"){
            setUrl("https://www.facebook.com/groups/formulacorrectiva8")
        }
        if(item.diplo === "Formula" && item.grupo === "Grupo9"){
            setUrl("https://www.facebook.com/groups/formulacorrectiva9")
        }
        
        
    })}}


    

    useEffect(() => {
        T();
    },[open3])

    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("status", "==", 'Planejamento en curso'), where("Prof", "==", nome));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        setRetorno(array)
    }

    useEffect(() => {
        getResumos();
    }, [nome])

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("status", "==", 'Planejamento en curso'), where("numeroCaso", "==", cas), where("email", "==", emailAluno), where("ifoUsuario.diplo", "==", diplo), orderBy("numeroCaso"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    useEffect(() => {
        getResumos2();
    }, [cas, diplo])


    const pickImage = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading2(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url + '.pdf');
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    useEffect(() => {
        setUploading2(false);
    },[referencia])

    
    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                   
                        <View>
                            <Text style={styles.Title}>Caso Clínico</Text>
                        </View>

                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                    <View style={{position: "absolute", top: 30, right: 150}}>
                        <Text style={{fontSize: 30, textAlign: 'center', color: "#FFF", fontWeight: "bold"}}>Nº Casos: {retorno.length}</Text>
                    </View>
                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginTop: 30, marginBottom: 30 }}>

                    <>

                    <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row'}}>
                        <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("FilaProfessor")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Fila de espera</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFF", margin: 20,
                        }}>

                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold" }}>Meus casos clínicos</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }}
                        onPress={() => navigation.navigate("AguardandoAluno")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aguardando Arquivos</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                            onPress={() => navigation.navigate("ProfConsulta")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Pesquisa de Casos Clínicos</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={{
                            backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                            onPress={() => navigation.navigate("CasosConcluidosProf")}>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Meus casos finalizados</Text>
                        </TouchableOpacity>

                    </View>

                    <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row'}}>

                        {permissaoDAO === "Sim" &&
                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                                onPress={() => navigation.navigate("FilaProfessorDAO")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Fila)</Text>
                            </TouchableOpacity>
                        }

                        {permissaoDAO === "Sim" &&
                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                                onPress={() => navigation.navigate("CasosConcluidosDAO")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Finalizados)</Text>
                            </TouchableOpacity>
                        }

                    </View>

                    </>
                    
                </View>
                <View style={{justifyContent: "center",  alignItems: "center"}}>
                {inicio === false &&
                    <>
                        <ScrollView horizontal={true} style={{flex: 1,}}>
                        <View style={{margin: 30,}}>
                            <View style={{flexDirection: 'row',  justifyContent: "center",  alignItems: "center"}}>
                                    <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                                    </View>
                                    <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Email</Text>
                                    </View>
                                    <View style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Grupo</Text>
                                    </View>
                                    <View style={{backgroundColor: "#FAEDB5", width: 180, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Diplomado</Text>
                                    </View>
                                    <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 10, fontWeight: "bold"}}>No. Caso Clínico</Text>
                                    </View>
                                    
                                    <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Abrir Documentos</Text>
                                    </View>
                            </View>
                           
                            
                            {retorno && retorno.map((item) => {
                                return (
                                    <View style={{flexDirection: 'row', justifyContent: "center", alignItems: "center"}}>
                                    <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.email}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{(item.grupo).replace(/Grupo(\d+)/g, "Grupo $1")}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 180, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.diplo}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.numeroCaso}</Text>
                                    </View>

                                   
                                    <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15}}>

                                        <TouchableOpacity onPress={() => {
                                            setEmailAluno(item.email),
                                            setCas(item.numeroCaso),
                                            setDiplo(item.diplo)
                                            setInicio(true)
                                        }}>
                                            <Text style={{color: "#fff", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Ver Caso Clínico</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>

                                )})}
                        </View>
                        </ScrollView>
                    </>

                }
                </View>

                {inicio !== false &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                        {open3 === true &&
                            <View>
                                <Text style={styles.textFacebook}>Postar no Facebook?</Text>

                                <View style={styles.viewBotoes}>
                                    <TouchableOpacity style={styles.botaoNao} onPress={() => {setOpen4(true), setFacebook("Não")}}>
                                        <Text style={styles.textBotoes}>Não</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={styles.botaoSim} onPress={() => {
                                        setOpen5(true),
                                        Linking.openURL(url),
                                        setFacebook("Sim")
                                    }}>
                                        <Text style={styles.textBotoes}>Sim</Text>
                                    </TouchableOpacity>

                                </View>

                                {open4 === true &&
                                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 20 }}>

                                <TextInput style={styles.input}
                                        placeholder="Motivo..."
                                        placeholderTextColor="#000000"
                                        type="text"
                                        multiline = {true}
                                        numberOfLines = {4}
                                        onChangeText={(text) => setMotivo(text)}
                                        value={motivo} />

                                        <TouchableOpacity style={styles.botaoEnviar} onPress={async () => {

                                                const db = getFirestore();
                                                const docRef = await addDoc(collection(db, "Resumos"), {
                                                    
                                                    to: [item.email],
                                                    message: {
                                                        html: '<p>Hola Doc. ' + (item.nome) + '</p><p>' + email2 + '</p><p>Un abrazo,</p><p>'+ ` ${nome} ` +'</p><a href="'+ `${referencia}`+'" target="_blank">Haga clic aquí para descargar el archivo</a></p><p>Equipo de Profesores - Dra. Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                        subject: 'Caso Clínico ' + (item.numeroCaso) + ' - Diplomado ' + (item.diplo) + ' - Grupo ' + (item.grupo).substr(5),
                                                    },
                                                    email: item.email,
                                                    ifoUsuario: item.consulta,
                                                    Anamnesis: item.Anamnesis,
                                                    DocPaciente: item.DocPaciente,
                                                    CefalometriaPaciente: item.CefalometriaPaciente,
                                                    data: Timestamp.fromDate(new Date()),
                                                    Envio: item.Envio,
                                                    numeroCaso: item.numeroCaso,
                                                    status: "Concluido", 
                                                    validata: item.validata,
                                                    initData: item.initData,
                                                    Prof: nome,
                                                    Planej: dataAtual,
                                                    face: facebook,
                                                    tipo: "CASO",
                                                    Motivo: motivo,
                                                    Arquivo: referencia
                                                });

                                                const docRef2 = await addDoc(collection(db, "Resumos"), {
                                                    
                                                    to: ["academico@rayanepinto.com"],
                                                    message: {
                                                        html: '<p>Hola Doc. ' + (item.nome) + '</p><p>' + email2 + '</p><p>Un abrazo,</p><p>'+ ` ${nome} ` +'</p><a href="'+ `${referencia}`+'" target="_blank">Haga clic aquí para descargar el archivo</a></p><p>Equipo de Profesores - Dra. Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                        subject: 'Caso Clínico ' + (item.numeroCaso) + ' - Diplomado ' + (item.diplo) + ' - Grupo ' + (item.grupo).substr(5),
                                                    },
                                                    email: item.email,
                                                    ifoUsuario: item.consulta,
                                                    Anamnesis: item.Anamnesis,
                                                    DocPaciente: item.DocPaciente,
                                                    CefalometriaPaciente: item.CefalometriaPaciente,
                                                    data: Timestamp.fromDate(new Date()),
                                                    Envio: item.Envio,
                                                    numeroCaso: item.numeroCaso,
                                                    status: "Concluido", 
                                                    validata: item.validata,
                                                    initData: item.initData,
                                                    Prof: nome,
                                                    Planej: dataAtual,
                                                    face: facebook,
                                                    tipo: "CASO",
                                                    Motivo: motivo,
                                                    Arquivo: referencia
                                                });
                                             
                                             await deleteDoc(doc(db, "Resumos", item.id));
                                             alert("Caso Clínico Finalizado", "...")
                                             navigation.navigate("FilaProfessor")
                                        }}>
                                            <Text style={styles.textEnviar}>Enviar</Text>
                                        </TouchableOpacity>

                                </View>
                                }

                                {open5 === true && open4 === false &&
                                    <Animatable.View style={styles.viewFacebook} animation="pulse" 
                                    easing="ease-out" iterationCount="infinite">

                                        <TouchableOpacity style={styles.botaoConfirmacao} onPress={async () => {

                                             const db = getFirestore();
                                             const docRef = await addDoc(collection(db, "Resumos"), {
                                                to: [item.email],
                                                message: {
                                                    subject: 'Caso Clínico ' + (item.numeroCaso) + ' - Diplomado ' + (item.diplo) + ' - Grupo ' + (item.grupo).substr(5),
                                                    html: '<p>Hola Doc. ' + (item.nome) + '</p><p>' + email2 + '</p><p>Un abrazo,</p><p>'+ ` ${nome} `+'</p><a href="'+ `${referencia}`+'" target="_blank">Haga clic aquí para descargar el archivo</a></p><p>Equipo de Profesores - Dra. Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                },
                                                 email: item.email,
                                                 ifoUsuario: item.consulta,
                                                 Anamnesis: item.Anamnesis,
                                                 DocPaciente: item.DocPaciente,
                                                 CefalometriaPaciente: item.CefalometriaPaciente,
                                                 data: Timestamp.fromDate(new Date()),
                                                 Envio: item.Envio,
                                                 numeroCaso: item.numeroCaso,
                                                 status: "Concluido", 
                                                 validata: item.validata,
                                                 initData: item.initData,
                                                 Prof: nome,
                                                 Planej: dataAtual,
                                                 face: facebook,
                                                 tipo: "CASO",
                                                 Motivo: motivo,
                                                 Arquivo: referencia
                                             });

                                             const docRef2 = await addDoc(collection(db, "Resumos"), {
                                                to: ["academico@rayanepinto.com"],
                                                message: {
                                                    html: '<p>Hola Doc. ' + (item.nome) + '</p><p>' + email2 + '</p><p>Un abrazo,</p><p>'+ ` ${nome} ` +'</p><a href="'+ `${referencia}`+'" target="_blank">Haga clic aquí para descargar el archivo</a></p><p>Equipo de Profesores - Dra. Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                    subject: 'Caso Clínico ' + (item.numeroCaso) + ' - Diplomado ' + (item.diplo) + ' - Grupo ' + (item.grupo).substr(5),
                                                },
                                                 email: item.email,
                                                 ifoUsuario: item.consulta,
                                                 Anamnesis: item.Anamnesis,
                                                 DocPaciente: item.DocPaciente,
                                                 CefalometriaPaciente: item.CefalometriaPaciente,
                                                 data: Timestamp.fromDate(new Date()),
                                                 Envio: item.Envio,
                                                 numeroCaso: item.numeroCaso,
                                                 status: "Concluido", 
                                                 validata: item.validata,
                                                 initData: item.initData,
                                                 Prof: nome,
                                                 Planej: dataAtual,
                                                 face: facebook,
                                                 tipo: "CASO",
                                                 Motivo: motivo,
                                                 Arquivo: referencia
                                             });
                                        
                                             await deleteDoc(doc(db, "Resumos", item.id));
                                             alert("Caso Clínico Finalizado", "...")
                                             navigation.navigate("FilaProfessor")
                                        }}>
                                            <Text style={styles.textConfirmação}>Confirmar postagem no facebook e finalizar</Text>
                                        </TouchableOpacity>

                                    </Animatable.View>
                                }
                            </View>
                        }

                        {open3 !== true &&

                        <View>

                            <TouchableOpacity style={styles.buttonFechar}
                                    onPress={() => {setInicio(false)}}
                                >
                                    <Text style={styles.textButtonFechar}>X</Text>
                            </TouchableOpacity>

                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Nº Caso Clínico: {item.numeroCaso}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.Envio}        {item.diplo}</Text>
                            </View>

                            <View style={styles.viewDocs}>
                                <TouchableOpacity style={styles.buttonView1} onPress={() => {
                                        Linking.openURL(item.Anamnesis);
                                        }}>
                                        <Text style={styles.textView}>📄 Guía de Anamnesis</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView2} onPress={() => {
                                        Linking.openURL(item.DocPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Documentação do Paciente</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView3} onPress={() => {
                                        Linking.openURL(item.CefalometriaPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Cefalometría</Text>
                                </TouchableOpacity>
                                </View>

                                <View style={styles.viewPlanej}>

                                    {uploading2 === false && referencia === null ?
                                
                                        <TouchableOpacity style={styles.buttonOk} onPress={() => {pickImage(), setOpen2(true)}}>
                                            <Text style={styles.textAnexar}>Anexar planejamento do caso clínico</Text>
                                        </TouchableOpacity>
                                        :
                                        <ActivityIndicator style={{ margin: 25 }} animating={uploading2} size={60} color="#FFF" />
                                        }

                                        {uploading2 === false && referencia !==  null &&
                                        <View style={styles.viewUpload}>
                                            <Text style={styles.nomeImg}>📄 {image}</Text>
                                    
                                            <TouchableOpacity onPress={() => {setReferencia(null), setImage(null)}} style={styles.botaoEliminar}>
                                                <Text style={styles.textEliminar}>X</Text>
                                            </TouchableOpacity>
                                        </View>
                                        }   

                                        {open2 === true &&

                                        <TextInput style={styles.input}
                                            placeholder="Mensagem de Envio"
                                            placeholderTextColor="#000000"
                                            type="text"
                                            multiline = {true}
                                            numberOfLines = {4}
                                            onChangeText={(text) => setEmail2(text)}
                                            value={email2} />
                                        }

                                        {open2 === true && email2 === "" &&

                                        <TouchableOpacity style={styles.botaoEnv}>

                                            <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Próximo</Text>
                                        </TouchableOpacity>

                                        }


                                        {open2 === true && email2 !== "" &&

                                        <TouchableOpacity style={styles.buttonProblema2} onPress={() => {
                                            setOpen3(true)
                                        }}>
                                            <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Próximo</Text>
                                        </TouchableOpacity>
                                        }

                                </View>
                            </View>
                        }
                        </>
                    )
                    })}
                </View>
                }
            
            </View>
        </>
    )

}